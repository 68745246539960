import { appSelectors } from '../store/app';
import { map, of, switchMap, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { GlobalState } from '../store';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  EditCancelledGuardActions,
  EditEventEffectsActions,
  editEventSelectors,
  EditSuccessfulGuardActions,
} from '../../features/edit-event/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  UploadCancelledGuardActions,
  UploadEventEffectsActions,
  UploadSuccessfulGuardActions,
} from '../../features/upload-event/store';
import { Language } from '../models/app.models';
import { JWT_KEY, SUPPORTED_LANGUAGES } from '../constants';
import {
  AppEffectsActions,
  GuardActions,
  LanguageGuardActions,
  LoggedInGuardActions,
} from '../store/actions';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { getCookieName } from '../helpers/helpers';
import { environment } from '../../../environments/environment';

export const isAuthActive = () => {
  const store: Store<GlobalState> = inject<Store<GlobalState>>(Store);
  const router: Router = inject(Router);

  return store.select(appSelectors.selectAuthActive).pipe(
    take(1),
    switchMap((authActive) => {
      if (!authActive) {
        return store.select(appSelectors.selectLanguage).pipe(
          tap((language) => router.navigate([language, 'login'])),
          map(() => false),
        );
      }
      return of(true);
    }),
  );
};

export const editWasCancelled = () => {
  const store: Store<GlobalState> = inject<Store<GlobalState>>(Store);
  const actions: Actions = inject<Actions>(Actions);

  if (localStorage.getItem('editEvent')) {
    localStorage.removeItem('editEvent');
  }

  setTimeout(
    () =>
      store.dispatch(EditCancelledGuardActions.editEventPaymentUnsuccessful()),
    0,
  );
  return actions.pipe(
    ofType(UploadEventEffectsActions.uploadEventFromStorageSuccessful),
    take(1),
    map(() => true),
  );
};

export const editWasSuccessful = () => {
  const store: Store<GlobalState> = inject<Store<GlobalState>>(Store);
  const actions: Actions = inject<Actions>(Actions);
  const router: Router = inject(Router);

  if (!localStorage.getItem('editEvent')) {
    return store.select(appSelectors.selectLanguage).pipe(
      tap((language) => router.navigate([language, 'my-events'])),
      map(() => false),
    );
  }

  setTimeout(
    () =>
      store.dispatch(EditSuccessfulGuardActions.editEventPaymentSuccessful()),
    0,
  );
  return actions.pipe(
    ofType(EditEventEffectsActions.editEventFromStorageSuccessful),
    take(1),
    map(() => true),
  );
};

export const hasEditableEvent = () => {
  const store: Store<GlobalState> = inject<Store<GlobalState>>(Store);
  const router: Router = inject(Router);

  return store.select(editEventSelectors.selectEventInformation).pipe(
    take(1),
    switchMap((eventInformation) => {
      if (eventInformation.id === 0) {
        return store.select(appSelectors.selectLanguage).pipe(
          tap((language) => router.navigate([language, 'my-events'])),
          map(() => false),
        );
      }
      return of(true);
    }),
  );
};

export const setLanguage = (next: ActivatedRouteSnapshot) => {
  const store: Store<GlobalState> = inject<Store<GlobalState>>(Store);
  const router: Router = inject(Router);
  const translationService: TranslateService = inject(TranslateService);
  const actions: Actions = inject<Actions>(Actions);

  const languageFromRouter = next.params['lang'] as Language;
  if (!SUPPORTED_LANGUAGES.includes(languageFromRouter)) {
    router.navigate(['/']);
    return false;
  }

  if (
    translationService.currentLang &&
    translationService.currentLang === languageFromRouter
  ) {
    return true;
  }

  // setTimeout is needed to dispatch the action after we subscribed in the return statement
  setTimeout(
    () =>
      store.dispatch(
        LanguageGuardActions.languageChanged({ language: languageFromRouter }),
      ),
    0,
  );
  return actions.pipe(
    ofType(AppEffectsActions.languageUpdated),
    take(1),
    map(() => true),
  );
};

export const isLoggedIn = () => {
  const store: Store<GlobalState> = inject<Store<GlobalState>>(Store);
  const router: Router = inject(Router);
  const cookieService: CookieService = inject(CookieService);
  const actions: Actions = inject<Actions>(Actions);

  if (!cookieService.get('izzo-admin-session')) {
    return store.select(appSelectors.selectLanguage).pipe(
      tap((language) => router.navigate([language, 'login'])),
      map(() => false),
    );
  }

  setTimeout(
    () => store.dispatch(LoggedInGuardActions.enteredPageUnlogged()),
    0,
  );
  return actions.pipe(
    ofType(
      AppEffectsActions.sessionLoginIsValid,
      AppEffectsActions.alreadyLoggedIn,
    ),
    take(1),
    map(() => true),
  );
};

export const isLoggedOut = () => {
  const store: Store<GlobalState> = inject<Store<GlobalState>>(Store);
  const router: Router = inject(Router);
  const cookieService: CookieService = inject(CookieService);

  return store.select(appSelectors.selectLoggedIn).pipe(
    take(1),
    switchMap((loggedIn) => {
      if (loggedIn || cookieService.get('izzo-admin-session')) {
        return store.select(appSelectors.selectLanguage).pipe(
          tap((language) => router.navigate([language, 'my-events'])),
          map(() => false),
        );
      }
      return of(true);
    }),
  );
};

export const enterWithStart = () => {
  const store: Store<GlobalState> = inject<Store<GlobalState>>(Store);
  const router: Router = inject(Router);
  const cookieService: CookieService = inject(CookieService);
  const actions: Actions = inject<Actions>(Actions);

  let loginData: any;
  const izzoSession = cookieService.get('izzo-admin-session');

  try {
    loginData = JSON.parse(cookieService.get(getCookieName(environment.env)));
  } catch (e) {
    router.navigate(['de', 'login']);
    return false;
  }

  if (loginData && !izzoSession) {
    setTimeout(
      () =>
        store.dispatch(
          GuardActions.StartWebsiteLogin({
            loginResponse: loginData,
          }),
        ),
      0,
    );
    return actions.pipe(
      ofType(
        AppEffectsActions.twoFactorActive,
        AppEffectsActions.twoFACodeGenerated,
      ),
      take(1),
      map(() => true),
    );
  }

  router.navigate(['de', 'login']);
  return false;
};

export const enterWithStartSkip = () => {
  const store: Store<GlobalState> = inject<Store<GlobalState>>(Store);
  const router: Router = inject(Router);
  const cookieService: CookieService = inject(CookieService);
  const actions: Actions = inject<Actions>(Actions);

  let loginData: any;
  const izzoSession = cookieService.get('izzo-admin-session');

  try {
    loginData = JSON.parse(cookieService.get(getCookieName(environment.env)));
  } catch (e) {
    router.navigate(['de', 'login']);
    return false;
  }

  console.log(loginData);

  if (loginData && !izzoSession) {
    cookieService.set('izzo-admin-session', loginData.session, {
      path: '/',
      expires: 7,
    });
    cookieService.set(JWT_KEY, loginData.key, {
      path: '/',
      expires: 7,
    });
    setTimeout(
      () =>
        store.dispatch(
          GuardActions.StartWebsiteLoginSkipped({
            loginResponse: loginData,
          }),
        ),
      0,
    );
  }

  router.navigate(['de', 'login']);
  return false;
};

export const uploadWasCancelled = () => {
  const store: Store<GlobalState> = inject<Store<GlobalState>>(Store);
  const actions: Actions = inject<Actions>(Actions);

  if (localStorage.getItem('newEvent')) {
    localStorage.removeItem('newEvent');
  }

  setTimeout(
    () =>
      store.dispatch(
        UploadCancelledGuardActions.uploadEventPaymentUnsuccessful(),
      ),
    0,
  );
  return actions.pipe(
    ofType(UploadEventEffectsActions.uploadEventFromStorageSuccessful),
    take(1),
    map(() => true),
  );
};

export const uploadWasSuccessful = () => {
  const store: Store<GlobalState> = inject<Store<GlobalState>>(Store);
  const actions: Actions = inject<Actions>(Actions);
  const router: Router = inject(Router);

  if (!localStorage.getItem('newEvent')) {
    return store.select(appSelectors.selectLanguage).pipe(
      tap((language) => router.navigate([language, 'my-events'])),
      map(() => false),
    );
  }

  setTimeout(
    () =>
      store.dispatch(
        UploadSuccessfulGuardActions.uploadEventPaymentSuccessful(),
      ),
    0,
  );
  return actions.pipe(
    ofType(UploadEventEffectsActions.uploadEventFromStorageSuccessful),
    take(1),
    map(() => true),
  );
};
